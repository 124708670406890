import Box from '@mui/material/Box'
import styled from 'styled-components'
import { useEffect, useContext, useState } from 'react'
import SwipeableMenu from './SwipeableDrawer'
import MenuIcon from './MenuIcon'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../hooks/useAuth'
import { translateGroup } from '../../../../../utils/auth'
import findTabFromGroup from '../Tabs/FindTabFromGroup'
import useTabs from '../Tabs'
import { getRedirectUrl } from '../../../../../utils/string'
import AlertModal, { AlertType } from '../../../../../components/modal/AlertModal'
import { CompanyContext } from '../../../../../states/company/CompanyContext'

interface IMobileNavBarProps {
  className?: string
}

const MobileNavBar = ({ className }: IMobileNavBarProps) => {
  const [showMenu, setShowMenu] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { defaultCompany } = useContext(CompanyContext)
  const [selectedTab, setSelectedTab] = useState<string>('Menu')
  const { groups } = useAuth()
  const group = translateGroup(groups?.[0])
  const [customerTabs, buyerTabs] = useTabs()
  const redirectUrl = getRedirectUrl() as string

  const alertModalConfig = ['2', '5', '11'].includes(String(defaultCompany?.statusId))
    ? {
        type: 'success',
        texts: {
          title: 'Cadastro enviado para análise!',
          description: (
            <span>
              Aguarde enquanto <span style={{ fontWeight: 'bold' }}>analisamos sua documentação.</span> Entraremos em
              contato em breve para instruí-lo(a) sobre as próximas etapas.
            </span>
          ),
          confirmButton: 'Entendi'
        }
      }
    : {
        type: 'warning',
        texts: {
          title: 'Finalize seu cadastro!',
          description: (
            <span>
              <span style={{ fontWeight: 'bold' }}>Preencha os dados</span> da empresa e dos sócios e{' '}
              <span style={{ fontWeight: 'bold' }}>envie a documentação</span> necessária!
            </span>
          ),
          confirmButton: 'Finalizar'
        }
      }

  useEffect(() => {
    const currentTab =
      group === 'Customer'
        ? findTabFromGroup(customerTabs, location.pathname)
        : findTabFromGroup(buyerTabs, location.pathname)
    if (currentTab) {
      setSelectedTab(currentTab.label)
    }

    if (currentTab?.label === 'Home') {
      setSelectedTab('Menu')
    }
  }, [buyerTabs, customerTabs, group])

  const navigate = useNavigate()

  const toggleDrawer = () => {
    setShowMenu(!showMenu)
  }

  const handleClick = (path: string, redirectToV1?: boolean, blocked?: boolean) => {
    if (blocked) {
      setModalOpen(true)
      setShowMenu(false)
      return
    }

    if (redirectToV1) {
      return window.location.replace(`${redirectUrl}${path}`)
    }

    navigate(path)
  }

  return (
    <div className={className} data-testid="mobile-nav-bar-test">
      <div key="top">
        <SwipeableMenu
          handleClick={handleClick}
          isOpen={showMenu}
          onClose={() => setShowMenu(false)}
          onOpen={() => setShowMenu(true)}
          selectedTab={selectedTab}
        />
      </div>
      <Box className="menu-item">
        <MenuIcon
          data-testid="menu-icon-test"
          toggleDrawer={toggleDrawer}
          showMenu={showMenu}
          selectedTab={selectedTab}
        />
      </Box>
      <AlertModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        submit={() => setModalOpen(false)}
        type={alertModalConfig.type as AlertType}
        texts={alertModalConfig.texts}
      ></AlertModal>
    </div>
  )
}

export default styled(MobileNavBar)`
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;

  @media (min-width: 601px) {
    display: none;
  }

  .menu-item {
    background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
    margin-top: 56px;
    position: relative;
    z-index: 2500 !important;
  }
`
