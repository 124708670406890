import styled from 'styled-components'
import CompanyWelcomeForm from '../../../companies/CompanyWelcomeForm'
import { CircularProgress } from '@mui/material'
import FileCard from '../../../cards/FileCard'
import PrimaryButton from '../../../buttons/PrimaryButton'
import CrudCreateModal from '../../CrudCreateModal'

interface IDocumentGroup {
  title: string
  documentTypes: IDocumentType[]
}

export interface IDocumentType {
  tagName: string
  tagColor: string
  name: string
  key: string
}

export interface IPrimaryFileSplitCrudData {
  basePath: string
  presentation: {
    title: string
    description: string[]
  }
  listDocumentGroup: IDocumentGroup[]
  isLoading?: boolean
  submit?: () => void
  isSubmitting?: boolean
  closeCreateModal?: () => void
  openModal?: boolean
  isDisabled?: boolean
}

interface IProps {
  className?: string
  data: IPrimaryFileSplitCrudData
}

const List: React.FC<IProps> = ({ className, data }: IProps) => {
  return (
    <div className={className}>
      {data?.isLoading ? (
        <div className="list__loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      ) : (
        <>
          {data?.closeCreateModal && data?.openModal && (
            <CrudCreateModal open={!!data?.openModal} onClose={data?.closeCreateModal} />
          )}
          <CompanyWelcomeForm header={data?.presentation?.title} descriptionText={data?.presentation?.description} />
          <div className="list__card--wrapper">
            {data?.listDocumentGroup?.map((documentGroup) => (
              <div className="list__column--wrapper" key={documentGroup?.title}>
                <h5 data-testid="primary-file-split-crud-list-title-test">{documentGroup?.title}:</h5>
                {documentGroup?.documentTypes?.map((documentType) => (
                  <FileCard
                    key={documentType?.name}
                    title={documentType?.name}
                    tagName={documentType?.tagName}
                    tagColor={documentType?.tagColor}
                    redirectTo={`${data?.basePath}/form/${documentType?.key}`}
                  />
                ))}
              </div>
            ))}
          </div>
          {data?.submit && (
            <div className="list__button--container">
              <div className="list_button--wrapper">
                <PrimaryButton onClick={data?.submit} isLoading={!!data?.isSubmitting} disabled={!!data?.isDisabled}>
                  Enviar para análise
                </PrimaryButton>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default styled(List)`
  min-height: calc(100vh - 240px);

  .list__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 240px);
  }

  .list__card--wrapper {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 112px;

    .list__column--wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h5 {
        font-size: 16px;
        font-weight: bold;
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      }
    }
  }

  .list__button--container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .list_button--wrapper {
      width: 184px;
    }
  }

  @media (max-width: 900px) {
    .list__card--wrapper {
      gap: 16px;
      .list__column--wrapper {
        width: 100%;
      }
    }

    .list__button--container {
      width: 100%;

      .list_button--wrapper {
        width: 100%;
      }
    }
  }
`
