import styled from 'styled-components'
import ModalWrapper from '../../commons/ModalWrapper'
import PrimaryButton from '../../buttons/PrimaryButton'
import { FormEvent } from 'react'
interface IProps {
  className?: string
  open: boolean
  onClose: () => void
  submit: () => void
  isLoading?: boolean
  type: AlertType
  texts: {
    title: string
    description: string | React.ReactNode
    confirmButton: string
  }
}

export type AlertType = 'warning' | 'success' | 'error'

const AlertModal: React.FC<IProps> = ({ className, open, submit, isLoading, texts }: IProps) => {
  const handleSubmit = (e: FormEvent) => {
    e?.preventDefault()
    submit()
  }
  return (
    <ModalWrapper open={open} className={className}>
      <form className="modal__wrapper" onSubmit={handleSubmit} data-testid="modal-test">
        <div className="modal__text___wrapper">
          <h4>{texts.title}</h4>
          <p>{texts.description}</p>
        </div>
        <div className="modal__button___wrapper">
          <PrimaryButton isLoading={isLoading} type="submit" size="small" data-testid="modal-submit-button-test">
            {texts.confirmButton}
          </PrimaryButton>
        </div>
      </form>
      <div className="modal__side-color"></div>
    </ModalWrapper>
  )
}
export default styled(AlertModal)`
  .modal__side-color {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: ${({ type, theme: { colors, styleGuide } }) => {
      switch (type) {
        case 'warning':
          return styleGuide?.support.yellow
        case 'error':
          return styleGuide?.support.red
        default:
          return colors?.primary
      }
    }};
  }
  .modal__wrapper {
    .modal__text___wrapper {
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};

      h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 32px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
      }
    }
    .modal__button___wrapper {
      margin-top: 32px;
      display: grid;
      justify-content: end;
      button {
        width: 100%;
        max-width: 200px;
        display: block;
        background: ${({ type, theme: { colors, styleGuide } }) => {
          switch (type) {
            case 'warning':
              return styleGuide?.support.yellow
            case 'error':
              return styleGuide?.support.red
            default:
              return colors?.primary
          }
        }};
        border-color: ${({ type, theme: { colors, styleGuide } }) => {
          switch (type) {
            case 'warning':
              return styleGuide?.support.yellow
            case 'error':
              return styleGuide?.support.red
            default:
              return colors?.primary
          }
        }};
      }
    }
  }
`
