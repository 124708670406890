import styled from 'styled-components'
import ModalWrapper from '../../commons/ModalWrapper'
import success from '../../../assets/icons/success.svg'
import PrimaryButton from '../../buttons/PrimaryButton'

interface IProps {
  className?: string
  open: boolean
  onClose: () => void
}

const CrudCreateModal: React.FC<IProps> = ({ className, open, onClose }: IProps) => {
  return (
    <ModalWrapper open={open} className={className}>
      <div className="crud-create-modal__wrapper" data-testid="crud-delete-modal-test">
        <img src={success} alt="Ícone de alerta amarelo" />
        <div className="crud-create-modal__text___wrapper">
          <h4>Dados salvos com sucesso!</h4>
        </div>
        <div className="crud-create-modal__button___wrapper">
          <PrimaryButton onClick={onClose} data-testid="crud-create-modal-submit-button-test">
            Fechar
          </PrimaryButton>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default styled(CrudCreateModal)`
  .crud-create-modal__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    .crud-create-modal__text___wrapper {
      margin-top: 16px;
      color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      display: flex;
      align-items: center;

      h4 {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .crud-create-modal__button___wrapper {
      margin-top: 32px;
      width: 184px;
    }
  }
`
