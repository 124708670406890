import { AppBar, Box, CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { OriginContext } from '../../../../../states/origin/OriginContext'
import { AuthContext } from '../../../../../states/auth/AuthContext'
import CompaniesDropdown from '../CompaniesDropdown'
import Logout from '../../../assets/Logout'
import { CompanyContext } from '../../../../../states/company/CompanyContext'

interface IProps {
  className?: string
}

const HeaderAppBar = ({ className }: IProps) => {
  const { originInfos } = useContext(OriginContext)
  const { logout } = useContext(AuthContext)
  const [showMenu, setShowMenu] = useState(false)
  const { isGettingDefaultCompany } = useContext(CompanyContext)

  const handleLogOut = () => {
    logout()
  }

  return (
    <div className={className}>
      <AppBar className="app_bar_container" position="fixed">
        <div className="grid_container">
          <div className="grid_container__companies-list">
            <img
              className="logo-svg"
              data-testid="header-logo-test"
              height={32}
              alt={`${originInfos?.storeName} Logo`}
              src={originInfos?.logoMain}
            />
            <div className="grid_container___companies-list-dropdown">
              {isGettingDefaultCompany ? (
                <Box className="loading-content">
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <CompaniesDropdown
                  isOpen={showMenu}
                  onClose={() => setShowMenu(false)}
                  onOpen={() => setShowMenu(true)}
                />
              )}
            </div>
          </div>
          <div className="header_right_container" data-testid="logout-button-header-test" onClick={handleLogOut}>
            <Logout width={24} height={24} />
            <span>Sair</span>
          </div>
        </div>
      </AppBar>
    </div>
  )
}

export default styled(HeaderAppBar)`
  header {
    padding-right: unset !important;
  }
  .app_bar_container {
    box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
    z-index: 5000;
    min-height: 56px;
  }

  .grid_container {
    align-items: center;
    box-shadow: unset;
    background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    height: 32px;

    @media (max-width: 550px) {
      .logo-svg {
        display: none;
      }

      padding: 12px 16px;
    }

    .grid_container__companies-list {
      display: flex;
      align-items: center;

      .grid_container___companies-list-dropdown {
        margin-left: 16px;
        z-index: 5000;
        display: flex;
        align-items: center;
        max-width: 340px;
        width: 100%;

        @media (max-width: 550px) {
          max-width: 300px;
          margin-left: unset;
        }

        .loading-content {
          max-height: 24px;
          margin-left: 24px;
          @media (max-width: 550px) {
            margin-left: unset;
          }
        }
      }
    }
  }

  .header_right_container {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 70px;

    @media (max-width: 400px) {
      border-left: 2px solid ${({ theme }) => theme?.styleGuide?.dynamic?.primary?.main};
      padding-left: 24px;
    }

    ${Logout} {
      path {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }

    span {
      color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      font-size: 16px;
      padding-left: 8px;
    }
  }
`
