import { useContext } from 'react'
import { CompanyContext } from '../../../../../states/company/CompanyContext'
import { useParams } from 'react-router-dom'

export interface TabProps {
  label: string
  index: number
  path: string
  subTabs?: string[]
  redirectToV1?: boolean
  blockedTab?: boolean
}

const useTabs = (): [TabProps[], TabProps[]] => {
  const { defaultCompany } = useContext(CompanyContext)
  const { id } = useParams()

  const blockedTab = !['4', '12', '13'].includes(defaultCompany?.statusId ?? '')
  const CUSTOMER_TABS: TabProps[] = [
    { label: 'Home', index: 0, path: '/', redirectToV1: true, blockedTab },
    {
      label: 'Dados Cadastrais',
      index: 1,
      path: '/register-company',
      subTabs: [
        '/company-partners',
        '/company-partners/form',
        '/company-customers-and-suppliers',
        '/company-customers-and-suppliers/form',
        '/company-bank-account-info',
        '/company-bank-account-info/form',
        '/company-revenues',
        '/company-documents',
        '/company-documents/form/constitution',
        '/company-documents/form/revenue',
        '/company-documents/form/business-address',
        '/company-documents/form/extract',
        '/company-documents/form/group-declaration',
        '/company-documents/form/identification',
        '/company-documents/form/partner-address',
        '/company-documents/form/proxy'
      ]
    },
    { label: 'Agenda de recebíveis', index: 2, path: '/agenda-recebiveis', redirectToV1: true, blockedTab },
    { label: 'Meus títulos', index: 3, path: '/invoices', redirectToV1: true, blockedTab },
    { label: 'Conta consignada', index: 4, path: '/client-extract', blockedTab },
    { label: 'Usuários', index: 5, path: '/users', redirectToV1: true, blockedTab }
  ]

  const BUYER_TABS: TabProps[] = [
    { label: 'Home', index: 0, path: '/', redirectToV1: true, blockedTab },
    { label: 'Meus títulos', index: 1, path: '/invoices', redirectToV1: true, blockedTab },
    {
      label: 'Empresa',
      index: 2,
      path: '/register-company',
      subTabs: [
        '/company-partners',
        '/company-partners/form',
        '/company-witnesses',
        `/company-witness/form/${id}`,
        '/company-taxes',
        '/company-users',
        `/company-users/form/${id}`,
        '/company-documents',
        '/company-documents/form/constitution',
        '/company-documents/form/revenue',
        '/company-documents/form/business-address',
        '/company-documents/form/extract',
        '/company-documents/form/group-declaration',
        '/company-documents/form/identification',
        '/company-documents/form/partner-address',
        '/company-documents/form/proxy'
      ]
    },
    { label: 'Parcelas', index: 3, path: '/parcelas-fin', redirectToV1: true, blockedTab }
  ]

  const APPROVED_BUYER_TABS: TabProps[] = [
    { label: 'Home', index: 0, path: '/', redirectToV1: true, blockedTab },
    { label: 'Cedentes', index: 1, path: '/customers-list', subTabs: [`/customer-profile/${id}`], blockedTab },
    { label: 'Meus títulos', index: 2, path: '/invoices', redirectToV1: true, blockedTab },
    {
      label: 'Empresa',
      index: 3,
      path: '/register-company',
      subTabs: [
        '/company-partners',
        '/company-partners/form',
        '/company-witnesses',
        `/company-witness/form/${id}`,
        '/company-taxes',
        '/company-users',
        `/company-users/form/${id}`,
        '/company-documents',
        '/company-documents/form/constitution',
        '/company-documents/form/revenue',
        '/company-documents/form/business-address',
        '/company-documents/form/extract',
        '/company-documents/form/group-declaration',
        '/company-documents/form/identification',
        '/company-documents/form/partner-address',
        '/company-documents/form/proxy'
      ]
    },
    { label: 'Parcelas', index: 4, path: '/parcelas-fin', redirectToV1: true, blockedTab },
    { label: 'Vitrine', index: 5, path: '/vitrine', redirectToV1: true, blockedTab }
  ]

  return [CUSTOMER_TABS, defaultCompany?.statusId === '4' ? APPROVED_BUYER_TABS : BUYER_TABS]
}

export default useTabs
