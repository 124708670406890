import { format, subDays } from 'date-fns'

export const formatDateToYYYYMMDD = (date: Date): string => format(date, 'yyyy-MM-dd')

export const subDaysAndFormatToYYYYMMDD = (date: Date, days: number): string => {
  const newDate = subDays(date, days)

  return formatDateToYYYYMMDD(newDate)
}

export const formatDateToMMDD = (date: Date): string => format(date, 'dd/MM')

export const formatDateToDDMMYYYY = (date: Date): string => format(date, 'dd/MM/yyyy')

export const formatDateToDDMMYYYYHHMM = (date: Date): string => format(date, 'dd/MM/yyyy HH:mm')

export const formatDateTimeToDDMMYYYYHHMMSS = (date: Date): string => format(date, 'dd/MM/yyyy HH:mm:ss')

export const getTimestamp = (date: Date): number => {
  return Math.round(+date / 1000)
}
